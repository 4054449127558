import React, {useEffect, useState} from 'react';
import './App.css';
import ArticleList from "./Article/Components/ArticleList";
import NewArticle from "./Admin/NewArticle/Components/NewArticle";
import 'react-quill/dist/quill.snow.css';
import Login from "./user/login/components/Login";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import RequireAuth from "./Auth/RequireAuth";
import useToken from "./Auth/Token";
import Register from "./user/register/components/Register";
import Navbar from "./Navbar/Navbar";
import Article from "./Article/Components/Article";
import EditArticle from "./Admin/NewArticle/Components/EditArticle";
import theme from "./Theme/Theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Information from "./Information";
import Gallery from "./Gallery";
import DocumentPdf from "./Document/DocumentPdf";
import ReactGA from 'react-ga';

ReactGA.initialize('G-PM4TXC1H2Q');

const App = () => {
    const {token} = useToken();
    const [searchValue, setSearchValue] = useState<string>("");
    const [refreshArticles, setRefreshArticles] = useState<boolean>(false);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App">
                    <header className="header">
                        <Navbar
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            refreshArticles={refreshArticles}
                            setRefreshArticles={setRefreshArticles}
                        />
                    </header>
                    <body>
                        <Routes>
                            <Route path="/" element={
                                <ArticleList
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    refreshArticles={refreshArticles}
                                    setRefreshArticles={setRefreshArticles}
                                />}
                            />
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/article/new" element={<NewArticle/>}/>
                            <Route path="/article/edit/:id" element={<EditArticle/>}/>
                            <Route path="/article/:id" element={<Article/>}/>
                            <Route path="/article" element={
                                <RequireAuth
                                    isLogged={token !== null}
                                >
                                  <NewArticle/>
                                </RequireAuth>
                            }/>
                            <Route path="/document/:id" element={<DocumentPdf/>}/>
                            <Route path="/info" element={<Information />}/>
                            <Route path="/gallery" element={<Gallery />}/>
                            <Route path="*" element={<h1>Przepraszamy. Nie znaleziono strony</h1>}/>
                        </Routes>
                    </body>
                </div>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
