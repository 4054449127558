import React, {useEffect, useState} from 'react';
import {ArticleDto} from "../types";
import {fetchArticleDto} from "../api";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, Divider, Link, Typography} from "@mui/material";
import ReplyIcon from '@mui/icons-material/Reply';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import NewComment from "./newComment/NewComment";
import useToken from "../../Auth/Token";
import {fetchDeleteComment} from "../../Admin/NewArticle/api";

const Article = () => {

    const [article, setArticle] = useState<ArticleDto>();
    const [reload, setReload] = useState<boolean>(false);
    const [openReplyForm, setOpenReplyForm] = useState<string>('');
    const {token} = useToken();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.id !== undefined) {
            fetchArticleDto(params.id, setArticle);
        }
        setReload(false);
    }, [reload]);

    const openForm = (commentId: string) => {
        setOpenReplyForm(commentId);
    }

    const handleDeleteComment = (articleId:string, commentId: string) => {
        fetchDeleteComment(articleId, commentId, setReload);
    }

    const getThumbnail = (thumbnail: string) => {
        if (thumbnail.startsWith("data:image/jpeg;base64,") || thumbnail.startsWith("data:image/png;base64,")) {
            return thumbnail;
        }
        return "data:image/jpeg;base64,"+thumbnail;
    }

    const handleDocument = (documentId: string | undefined) => {
        if (documentId != undefined) {
            navigate("/document/" + documentId);
        }
    }

    return (
        <Box
            className="article"
            sx={{
                maxWidth: 'xl',
            }}
        >
            <Box>
            <h1 className="title">{article?.title}</h1>
            <h2 className="author">{article?.author}</h2>
            <p className="creationDatetime">{article?.creationDatetime}</p>
                <Divider />
            {article?.thumbnail &&
                <img alt="zdjęcie" className="thumbnail" src={getThumbnail(article.thumbnail)}/>
            }
            {article?.content &&
                <div className="content" dangerouslySetInnerHTML={{__html: article?.content.replace(/(<? *script)/gi, 'illegalscript')}}>
                </div>
                    }
            {(article?.documents == undefined || article?.documents.length > 0) &&
                <div className="documents">
                    Załączniki:
                    <ul> {article?.documents.map((document, index) => {
                        return (<li key={document.id}>
                            <Link className="content"
                               key={document.id}
                               target="_blank"
                               onClick={() => handleDocument(document.id)}
                            >
                                {document.name}
                            </Link>
                        </li>)
                        // return (<li key={document.id}><a className="content" key={document.id} href={"data:application/pdf;base64,"+document.data} target="_blank">{document.name}</a></li>)
                    })
                    }
                    </ul>
                </div>
            }
            </Box>
            <Divider sx={{
                marginBottom: '10px'
            }}/>
            <Typography variant="h6">Komentarze:</Typography>
            {article?.comments.length === 0 && <p>Brak komentarzy</p>}
            {article?.comments.map((comment, index) => {
                    return (<Box key={comment.id} margin="10px">
                        <Typography><b style={{ color: '#219287'}}>{comment.author}</b> - {comment.creationDatetime}</Typography>
                        <Typography fontWeight="300">{comment.message}</Typography>
                        {token && <Button size="small" variant="outlined" onClick={() => handleDeleteComment(article?.id, comment.id)}>Usuń</Button>}
                        <Box sx={{marginLeft: "15px"}}>
                            {comment.comments.map((subcomment, index) => {
                                return (<Box
                                    key={subcomment.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center', // wyrównanie elementów w pionie
                                        margin: '10px'
                                    }}
                                >
                                    <SubdirectoryArrowRightIcon style={{ color: '#219287' }}/>
                                    <Box sx={{ flex: 1, marginLeft: 1 }}>
                                        <Typography><b style={{ color: '#219287'}}>{subcomment.author}</b> - {subcomment.creationDatetime}</Typography>
                                        <Typography fontWeight="300">{subcomment.message}</Typography>
                                        {token && <Button size="small" variant="outlined" onClick={() => handleDeleteComment(article?.id, subcomment.id)}>Usuń</Button>}
                                    </Box>
                                </Box>)
                            })}
                            <Button size="small" onClick={() => openForm(comment.id)}><ReplyIcon />Odpowiedz</Button>
                            {openReplyForm === comment.id && <NewComment articleId={article?.id} setReload={setReload} rootCommentId={comment.id} setOpenReplyForm={setOpenReplyForm}/>}
                        </Box>
                    </Box>)
                }
            )}
            <NewComment articleId={article?.id} setReload={setReload} />
        </Box>
    )
}

export default Article;