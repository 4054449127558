import {UpdateArticleDto, NewArticleDto} from "./types";
import {AxiosResponse} from "axios";
import {backendUrl} from "../../Global/globalValues";
import {axiosWithAuth, axiosFileWithAuth} from "../../Auth/axiosWithToken";
import {useNavigate} from "react-router-dom";

export const fetchNewArticleDto = (newArticleDto: NewArticleDto) => {
    axiosWithAuth().post<Response>(backendUrl + "/admin/article", newArticleDto).then((res: AxiosResponse) => {
        if (res.status >= 400) {
            alert("Błąd: " + res.status + ": " + res.data);
        } else if (res.status === 201) {
            alert("Dodano artykuł");
        }
    });
}


export const fetchEditArticleDto = (updateArticleDto: UpdateArticleDto, articleId: string | undefined) => {
    if (articleId !== undefined) {
        axiosWithAuth().put<Response>(backendUrl + "/admin/article/" + articleId, updateArticleDto).then((res: AxiosResponse) => {
            if (res.status >= 400) {
                alert("Błąd: " + res.status + ": " + res.data);
            } else if (res.status === 201) {
                alert("Edytowano artykuł");
            }
        });
    }
}

export const fetchDeleteArticleDto = (articleId: string | undefined) => {
    if (articleId !== undefined) {
        axiosWithAuth().delete<Response>(backendUrl + "/admin/article/" + articleId + "/delete").then((res: AxiosResponse) => {
            if (res.status >= 400) {
                alert("Błąd: " + res.status + ": " + res.data);
            } else if (res.status === 200) {
                alert("Usunięto artykuł");
            }
        });
    }
}

export const fetchDeleteComment = (articleId: string | undefined, commentId: string | undefined, setReload: (value: boolean) => void) => {
    if (articleId !== undefined) {
        axiosWithAuth().delete<Response>(backendUrl + "/article/" + articleId + "/comment/" + commentId).then((res: AxiosResponse) => {
            // TODO usunąć tokeny gdy token expired, dodać do url "/delete"
            if (res.status >= 400) {
                alert("Błąd: " + res.status + ": " + res.data);
            } else if (res.status === 200) {
                alert("Usunięto komentarz");
                setReload(true);
            }
        });
    }
}

export const fetchNewDocument = async (file: File | null): Promise<string | null> => {
    if (file != undefined) {
        const formData = new FormData();
        formData.append("document", file);

        return await axiosFileWithAuth().post<Response>(backendUrl + "/admin/document", formData).then((res: AxiosResponse) => {
            if(res.status === 403) {
                const navigate = useNavigate();
                navigate("/login");
        } else if (res.status >= 400) {
                alert("Błąd: " + res.status + ": " + res.data);
            } else if (res.status === 200) { // to fix to 201
                alert(res.data);
                return res.data;
            }
        });
    }
    return null;
}